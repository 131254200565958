import axios from "axios";
import { getSession, setSession, unsetSession } from "entities/sessions";
import { Paths } from "shared/config";
import { pMemoize } from "shared/lib/common";
import { handleNetworkError } from "shared/lib/error";

import { refreshSession } from "..";
import { AUTH_TOKEN_HEADER_KEY } from "./constants";

export const refreshTokenInterceptor = async (error: any) => {
  const config = error?.config;

  if (error.response?.status === 401 && !config?.sent) {
    config.sent = true;

    const { accessToken, refreshToken } = getSession();

    if (refreshToken) {
      const result = await refreshSessionMemo(accessToken!, refreshToken);

      if (result?.accessToken) {
        config.headers = {
          ...config.headers,
          [AUTH_TOKEN_HEADER_KEY]: `Bearer ${result.accessToken}`,
        };
      }

      return axios(config);
    } else {
      handleNetworkError("Access token is expired but no refresh token");
      unsetSession();
      window.location.href = Paths.Login;
    }
  }

  return Promise.reject(error);
};

const refreshSessionM = async (accessToken: string, refreshToken: string) => {
  try {
    const data = await refreshSession({
      accessToken,
      appVersion: "web", // TODO: to config
      refreshToken,
    });

    const newAccessToken = data.accessToken;
    const newRefreshToken = data.refreshToken;

    if (!newAccessToken || !newRefreshToken) {
      throw new Error("Missing tokens after session refresh");
    }

    setSession({
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
    });

    return {
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
    };
  } catch {
    handleNetworkError("Refresh token is expired or unexpected error");
    unsetSession();
    window.location.href = Paths.Login;
  }
};

const refreshSessionMemo = pMemoize(refreshSessionM);
