import dayjs from "dayjs";
import { OrderListItem } from "entities/orders";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GetOrdersParams, Order } from "shared/api";
import { paths } from "shared/config";
import { groupBy } from "shared/lib/lodash";
import { Box, Card, IconInfo, IconSearch, Stack, Typography } from "shared/ui";

import { getOrdersGroupTitle } from "../../lib";

export type OrdersListProps = {
  tradeOutletId: GetOrdersParams["tradeOutletId"];
};

export type OrdersListRendererProps = {
  orders: Order[];
  searchOrderNumber: string | undefined;
};

export const OrdersListRenderer = ({
  orders,
  searchOrderNumber,
}: OrdersListRendererProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  if (searchOrderNumber !== undefined && orders.length === 0) {
    return (
      <Stack alignItems="center">
        <Stack
          p={2}
          sx={{ borderRadius: "50%", backgroundColor: "neutral.95" }}
        >
          <IconSearch fontSize="large" />
        </Stack>
        <Typography variant="headlineH4" mt={2}>
          {t("orders.search.no_results.title")}
        </Typography>
        <Typography variant="regularTextRegular" color="neutral.50" mt={0.5}>
          {t("orders.search.no_results.description")}
        </Typography>
      </Stack>
    );
  }

  if (orders.length === 0) {
    return (
      <Card
        sx={{
          p: 2,
        }}
      >
        <Stack alignItems="flex-start" direction="row" spacing={2}>
          <Box
            bgcolor="#e1f5fe"
            display="inline-flex"
            p={0.5}
            borderRadius="6px"
          >
            <IconInfo color="info" />
          </Box>
          <Box>
            <Typography mb={0.5} variant="mediumTextMedium">
              {t("orders.empty.title")}
            </Typography>
            <Typography color="neutral.50" variant="regularTextRegular">
              {t("orders.empty.description")}
            </Typography>
          </Box>
        </Stack>
      </Card>
    );
  }

  const handleOrderClick = (orderId?: string) => () => {
    if (!orderId) {
      return; // TODO: Sentry? what to do
    }

    navigate(paths.order(orderId));
  };

  const grouped = groupBy(orders, (item) => {
    return dayjs(item.created).startOf("day").format();
  });

  const renderHighlightedOrderNumber = (value: string) => {
    if (!searchOrderNumber || searchOrderNumber?.length < 3) {
      return (
        <Typography color="neutral.20" variant="mediumTextSemiBold">
          #{value}
        </Typography>
      );
    }

    const parts = value.split(new RegExp(`(${searchOrderNumber})`, "gi"));
    return (
      <Typography color="neutral.20" variant="mediumTextSemiBold">
        #
        {parts.map((part) =>
          part.toLowerCase() === searchOrderNumber?.toLowerCase() ? (
            <Typography
              color="common.primary"
              variant="mediumTextSemiBold"
              display="inline"
              key={part}
            >
              {part}
            </Typography>
          ) : (
            part
          ),
        )}
      </Typography>
    );
  };

  return (
    <Stack spacing={3}>
      {Object.entries(grouped).map((entry) => {
        const [created, items] = entry;

        return (
          <Box key={created}>
            <Typography mb={2} variant="bigTextMedium">
              {getOrdersGroupTitle(created)}
            </Typography>
            <Stack spacing={1}>
              {items.map((order) => {
                return (
                  <OrderListItem
                    amount={order.amount}
                    key={order.id}
                    onClick={handleOrderClick(order.id)}
                    orderNumber={renderHighlightedOrderNumber(
                      order.orderNumber,
                    )}
                    status={order.status}
                    time={order.created}
                  />
                );
              })}
            </Stack>
          </Box>
        );
      })}
    </Stack>
  );
};
