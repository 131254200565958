import * as BaseSentry from "@sentry/react";
import { SENTRY_DSN } from "shared/config";

const init = () => {
  BaseSentry.init({
    dsn: SENTRY_DSN,
    enabled: import.meta.env.PROD,
  });
};

export const Sentry = {
  addBreadcrumb: BaseSentry.addBreadcrumb,
  captureException: BaseSentry.captureException,
  init,
  setUser: BaseSentry.setUser,
};
