import { enqueueSnackbar } from "@mychili/ui-web";

import { Sentry } from "../sentry";

export const handleNetworkError = (
  message: string,
  { sentry } = { sentry: true },
) => {
  if (sentry) {
    Sentry.captureException(message);
  }

  enqueueSnackbar(message, { variant: "error" });
};
