import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { handleNetworkError } from "shared/lib/error";

export type OrderStatus = bnplMerchants.GetExtendedOrderWithQrStatus;

export type GetOrderResponseData = {
  amount: bnplMerchants.GetExtendedOrderWithQr["amount"];
  applicationId: bnplMerchants.GetExtendedOrderWithQr["application_id"];
  archived: bnplMerchants.GetExtendedOrderWithQr["archived"];
  calcId: bnplMerchants.GetExtendedOrderWithQr["calc_id"];
  clientId: bnplMerchants.GetExtendedOrderWithQr["client_id"];
  contractId: bnplMerchants.GetExtendedOrderWithQr["contract_id"];
  created: bnplMerchants.GetExtendedOrderWithQr["created"];
  id: string;
  link: bnplMerchants.GetExtendedOrderWithQr["link"];
  linkQr: bnplMerchants.GetExtendedOrderWithQr["link_qr"];
  offerId: bnplMerchants.GetExtendedOrderWithQr["offer_id"];
  orderItems: bnplMerchants.OrderItemExcludeArchivedCreatedUpdated[];
  orderNumber: bnplMerchants.GetExtendedOrderWithQr["order_number"];
  status: bnplMerchants.GetExtendedOrderWithQrStatus;
  tradeOutletId: bnplMerchants.GetExtendedOrderWithQr["trade_outlet_id"];
  updated: bnplMerchants.GetExtendedOrderWithQr["updated"];
};

export const getOrder = async (id: string): Promise<GetOrderResponseData> => {
  const response =
    await bnplMerchants.getApiMerchantsServiceEmployeeOrdersId(id);

  if (!response.data.data) {
    handleNetworkError("getOrder: missing data");
    throw new Error("getOrder: missing data");
  }

  if (!response.data.data.id) {
    handleNetworkError("getOrder: missing order ID");
    throw new Error("getOrder: missing order ID");
  }

  return {
    amount: response.data.data?.amount,
    applicationId: response.data.data.application_id,
    archived: response.data.data.archived,
    calcId: response.data.data.calc_id,
    clientId: response.data.data.client_id,
    contractId: response.data.data.contract_id,
    created: response.data.data.created,
    id: response.data.data.id,
    link: response.data.data.link,
    linkQr: response.data.data.link_qr,
    offerId: response.data.data.offer_id,
    orderItems: response.data.data.order_items,
    orderNumber: response.data.data.order_number,
    status: response.data.data.status,
    tradeOutletId: response.data.data.trade_outlet_id,
    updated: response.data.data.updated,
  };
};
